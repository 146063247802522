import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import Login from "./Login/Login";
import Register from "./Login/Register";
import Dashboard from "./Dashboard";
import Test from "./Test";
import useToken from "./Token/useToken";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MainFrame from "./MainFrame/MainFrame";
import Logout from "./Logout/Logout.js";
import jwt from "jwt-decode";


import { GoogleOAuthProvider } from '@react-oauth/google';

export const UserContext = React.createContext(null);
export const PageContext = React.createContext(null);
export const TokenContext = React.createContext(null);
export const CardsContext = React.createContext(null);
export const AllUsersContext = React.createContext(null);

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const getToken = () => {
  const tokenString = sessionStorage.getItem("token");
  // debugger;
  let userToken = null;
  if (tokenString !== "undefined") {
    userToken = JSON.parse(tokenString);
    return userToken;
  }
  return undefined;
};

const getUser = (token) => {
  if(!!token){
  const tokenData = jwt(token);
      return {
        loginname: tokenData.username,
        username: tokenData.publicname,
        email: tokenData.email,
      };
  }
  else{
    return {
      loginname: "User",
      username: null,
      email: null
    };
  }
}

const getAllUsers = async (token) =>
{

  let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/all`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then(response => response.json())
  .catch(error => {return error})

  return response;
};

function App() {

  
  const [currentComponentName, setCurrentComponentName] = useState("");
  
  const [token, setToken] = useState(getToken());
  
  const [user, setUser] = useState(getUser(token));
  
  const [allUsers, setAllUsers] = useState([]);

  const saveToken = (userToken) => {
    sessionStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken);
  };
  
  //Autologout on token expire
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(()=>{
    if(token){
      const decodedJwt = JSON.parse(atob(token.split('.')[1]));
      if(decodedJwt)
      {
        if(decodedJwt.exp * 1000 < Date.now())
        {
          setToken(undefined);
          sessionStorage.clear();
        }
      }
    }
    
  },[location]);

  useEffect(()=>{
    const GetUsers = async (token) =>{
    let users = await getAllUsers(token);
    console.log("MinUsesrs:" , users);
    let stateUsers = JSON.parse(JSON.stringify(users));
    setAllUsers(stateUsers)
    //console.log("Users:" ,allUsers)
  }

    GetUsers(getToken());
  },[token]);

  const [cards,setCards] = useState([]);

  const saveCards = (cards) => {
    const uniqe = [...new Set(cards.map(o => JSON.stringify(o)))].map(s => JSON.parse(s));
    setCards(uniqe);
  };

  if (!token) {
    return (
      <>
        <ThemeProvider theme={darkTheme}>
          <TokenContext.Provider value={{ token: token, setToken: saveToken }}>
            <UserContext.Provider value={{ user: user, setUser: setUser }}>
              <CardsContext.Provider value={{cards: cards, setCards : saveCards}}>
                <AllUsersContext.Provider value={{allUsers: allUsers, setAllUsers: setAllUsers}}>
              <CssBaseline />
              <GoogleOAuthProvider clientId="332686778375-mknb18lhk24d3oiqupc647tf9c3ffjtg.apps.googleusercontent.com"
              onScriptLoadError={(res) => console.log("AuthProvError: ",res)}
              onScriptLoadSuccess={(res) => console.log("AuthProvGIt: ",res)}
              >
              <Routes>
                <Route
                  path="/login"
                  element={<Login setToken={saveToken} />}
                ></Route>
                <Route path="/register" element={<Register></Register>}></Route>
                <Route path="*" element={<Navigate to="/login" />}></Route>
              </Routes>
              </GoogleOAuthProvider>
              </AllUsersContext.Provider>
              </CardsContext.Provider>
            </UserContext.Provider>
          </TokenContext.Provider>
        </ThemeProvider>
      </>
    );
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <TokenContext.Provider value={{ token: token, setToken: saveToken }}>
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
        <CardsContext.Provider value={{cards: cards, setCards : saveCards}}>
        <AllUsersContext.Provider value={{allUsers: allUsers, setAllUsers: setAllUsers}}>
          <PageContext.Provider
            value={{
              currentComponentName: currentComponentName,
              setCurrentComponentName: setCurrentComponentName,
            }}
          >
            <CssBaseline />
            <Routes>
              <Route path="/main/*" element={<MainFrame />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/test" element={<Test />} />
              <Route path="/logout" element={<Logout setToken={saveToken} />} />
              <Route path="*" element={<Navigate to="/main" />} />
            </Routes>
          </PageContext.Provider>
          </AllUsersContext.Provider>
          </CardsContext.Provider>
        </UserContext.Provider>
      </TokenContext.Provider>
    </ThemeProvider>
  );
}

export default App;
