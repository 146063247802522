import { Box, Avatar, Paper, Typography, Stack } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../App";
import { pink } from "@mui/material/colors";
import SmartToyIcon from '@mui/icons-material/SmartToy';
export default function Message({ message }) {
  const {user,setUser} = useContext(UserContext);
  const isMyMsg = message.user !== user.username;
  const isBot = message.user === "Taliowisko Bot";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isMyMsg ? "flex-start" : "flex-end",
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMyMsg ? "row" : "row-reverse",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{ bgcolor: isMyMsg ? "secondary.main" : "secondary.light" }}
        >
          {isBot? <SmartToyIcon/> : (isMyMsg ? message.user.charAt(0) : user.username.charAt(0))}
        </Avatar>
        <Stack direction="column" justifyContent={isMyMsg ?"right":"left"} alignItems={isMyMsg? "flex-start" : "flex-end"}>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              ml: isMyMsg ? 1 : 0,
              mr: isMyMsg ? 0 : 1,
              backgroundColor: isMyMsg ? "primary.main" : "secondary.dark",
              borderRadius: isMyMsg
                ? "20px 20px 20px 2px"
                : "20px 20px 2px 20px",
            }}
          >
            <Typography variant="body1">{message.message}</Typography>
          </Paper>
          <Paper alignItems={isMyMsg? "flex-end" : "flex-start"}>
            <Typography marginRight={isMyMsg ? "0" : "10px"} marginLeft={isMyMsg ? "10px" : "0"}fontSize={11} variant="button">
              {message.user}
            </Typography>
          </Paper>
        </Stack>
      </Box>
    </Box>
  );
}
