import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useEffect, useContext } from "react";
import { UserContext } from "../App";

export default function Logout({ setToken }) {
  const {user, setUser} = useContext(UserContext);
  
  useEffect(() => {
    console.log("Log out!");
    setToken(undefined);
    
    setUser({
      username: null,
      email: null,
      loginname: null
    });
    sessionStorage.clear();
  }, []);

  return (
    <Box
      justifyContent="center"
      sx={{ display: "flex", alignItems: "center", height: "100vh" }}
    >
      <CircularProgress />
    </Box>
  );
}
