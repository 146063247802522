import { Avatar, Typography } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import PersonIcon from "@mui/icons-material/Person";
import { Stack, Box } from "@mui/material";
import { useContext, useEffect } from "react";
import { PageContext, UserContext } from "../App";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
function PublicProfile() {
  const { name } = useParams();
  const { user, setUser } = useContext(UserContext);

  const { currentComponentName, setCurrentComponentName } =
    useContext(PageContext);
  useEffect(() => {
    setCurrentComponentName(name + "'s profile");
  }, []);

  useEffect(() => {
    setCurrentComponentName(name + "'s profile");
  }, [name]);

  const navigate = useNavigate()

  return (
    <Box spacing={{ xs: 1, sm: 2 }}>
      <Stack
        margin={5}
        spacing={{ xs: 1, sm: 4 }}
        direction="row"
        alignItems="center"
      >
        <Avatar
          sx={{
            backgroundColor: green[500],
            width: 50,
            height: 50,
            mt: 5,
            ml: 5,
          }}
        >
          <PersonIcon fontSize="large" />
        </Avatar>

        <Typography variant="button" sx={{ fontSize: "2rem" }}>
          {" "}
          <nobr>{name}</nobr>{" "}
        </Typography>
      </Stack>
      <Stack margin={5} spacing={2} direction={'row'}>
      <Button variant="contained" onClick={() => {navigate(`../publicdecks/${name}`)}}>Show Decks</Button>
      <Button variant="contained" onClick={() => {navigate(`../collection/${name}`)}}>Show Collection</Button>
      <Button variant="contained" onClick={() => {navigate(`../priv/${name}`)}}>Start Chat</Button>
      </Stack>
    </Box>
  );
}

export default PublicProfile;
