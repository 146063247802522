import { useContext, useEffect, useState } from "react";
import { PageContext, TokenContext } from "../App";
import { Box,Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {Link} from "@mui/material";
import { pink } from "@mui/material/colors";
import { styled } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import {IconButton} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";

export default function Deals()
{
  const {token, setToken} = useContext(TokenContext)
  const [loading, setLoading] = useState(false);
  const [deals, setDeals] = useState();
  const {currentComponentName, setCurrentComponentName} = useContext(PageContext);
  const [v, setV] = useState(0);
    useEffect(() => {
      setCurrentComponentName("Deals");
      setLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/deal`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      //.then(response => console.log("DeckView:" , response))
      .then((response) => {
        if (!!response) {

          setDeals(response);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("DealsError: ", error);
        setLoading(false);
      });
    }, [v])


    

    const forceUpdate =  () => setV(v => v+1);
    const handleConfirm = (id) => {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/deal/confirm/${id}`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then(response => {
        console.log(response)
        if(response.ok)
        {
          console.log("plox")
          forceUpdate();
        }

      })
    };

    const columns = [
      {
        field: "actions",
        type: "actions",
        width: 100,
        getActions: (params) => [
          <GridActionsCellItem
          icon={
          <IconButton><CheckIcon  /></IconButton>}
          
          label="Show"
          onClick={()=>{handleConfirm(params.row.Id)}}
        />,
        ],
      },
      { field: "Id", headerName: "Id", width: 130 , valueGetter: (params) => {return params.row.Id}},
      { field: "Card", headerName: "Card Name", width: 200 , renderCell: (params) => {return <Link href={`/main/cards/${params.row.Card.Id}`}>{params.row.Card.Name}</Link>}},
      { field: "Price", headerName: "Price", width: 100 , valueGetter: (params) => {return params.row.Price + ' PLN'}},
      { field: "Buyer", color: pink[500],headerName: "Buyer", width: 150 , renderCell: (params) => {return <Link href={`/main/view/${params.row.Buyer}`}>{params.row.Buyer}</Link>}},
      { field: "Seller", headerName: "Seller", width: 150 ,renderCell: (params) => {return <Link href={`/main/view/${params.row.Seller}`}>{params.row.Seller}</Link>}},
      { field: "CreationDate", headerName: "Creation Date",type: "date", width: 100 , valueGetter: (params) => {return new Date(params.row.CreationDate)}},
      { field: "BuyerConfirm", headerName: "Buyer Confirmation", type: 'boolean', width: 150 , valueGetter: (params) => {return params.row.BuyerConfirm}},
      { field: "BuyerConfirmDate", headerName: "Buyer Confirm Date", type: "date" , width: 150 , valueGetter: (params) => {return (new Date(params.row.BuyerConfirmDate).getTime()) != (new Date("0001-01-01T00:00:00")).getTime() ? new Date(params.row.BuyerConfirmDate) : ''}},
      { field: "SellerConfirm", headerName: "Seller Confirmation",  type: 'boolean', width: 150 , valueGetter: (params) => {return params.row.SellerConfirm}},
      { field: "SellerConfirmDate", headerName: "Seller Confirm Date", width: 150 ,type: "date", valueGetter: (params) => {return (new Date(params.row.SellerConfirmDate).getTime()) != (new Date("0001-01-01T00:00:00")).getTime() ? new Date(params.row.SellerConfirmDate) : ''}},
      { field: "ImBuyer", headerName: "I'm Buyer", width: 150 ,type: "boolean", valueGetter: (params) => {return params.row.ImBuyer}},
    ];

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
      '& .deals-theme--ImSeller': {
        backgroundColor: '#4615b2',
        },
        '& .deals-theme--ImBuyer': {
          backgroundColor: '#007bb2',
          }
        
      }));

    return (
      <>
      {loading ? (
        <Box
          justifyContent="center"
          sx={{ display: "flex", alignItems: "center", minHeight: "100%" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Stack m={3} spacing={2} alignItems={"center"}>
          <div style={{ width: "100%" }}>
            <StyledDataGrid
              getRowClassName={(params) => `deals-theme--${params.row.ImBuyer ? 'ImBuyer' : 'ImSeller'}`}
              autoHeight
              rows={!!deals ? deals : []}
              columns={columns}
              getRowId={(deal) => deal.Id}
              initialState={{
                rowGrouping: { model: ['Price']},
                pagination: { paginationModel: { pageSize: 5 } },
                columns: {
                  columnVisibilityModel: {
                    Id: true,
                    ImBuyer: true
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, 100]}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { showQuickFilter: true },
              }}
            />
          </div>
        </Stack>
      )}
    </>
    );

}