import * as React from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from './NavBarItems';
import ViewProfile from '../ViewProfile/ViewProfile.js'
import { BrowserRouter as Router, Route, Routes, Link as RouterLink , Navigate, useNavigate} from "react-router-dom";
import { Autocomplete, TextField, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Copyright from "../Copyright/Copyright.js";
import { useTheme } from '@emotion/react';
import { AllUsersContext, PageContext } from '../App';
import Dashboard from '../Dashboard/Dashboard';
import MyCollection from '../MyCollection/MyCollection';
import MyDecks from '../MyDecks/MyDecks';
import Market from '../Market/Market';
import ChatRooms from '../ChatRooms/ChatRooms';
import Settings from '../Settings/Settings';
import Cards from '../Cards/Cards';
import Card from '../Cards/Card';
import Deck from '../MyDecks/Deck';
import Deals from '../Deals/Deals';
import PublicProfile from '../PublicProfile/PublicProfile';
import PrivRoom from '../ChatRooms/PrivRoom';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


export default function MainFrame() {
    //const defaultTheme = useTheme();
    const [open, setOpen] = React.useState(true);
    const {currentComponentName, setCurrentComponentName} = React.useContext(PageContext)
    const {allUsers, setAllUsers} = React.useContext(AllUsersContext);
    const toggleDrawer = () => {
      setOpen(!open);
    };
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = React.useState();
  
    const handelEnter = (event) => {
      if(event.key === 'Enter')
      {
        handleSearch(searchValue);
      }
    };
    const handleSearch = (v) => 
    {
      if(!!v){ 
        //console.log("aha:" ,v)
        navigate(`/main/view/${v.label}`);
        setSearchValue(null)
    }
    };

    const serachRef = React.createRef();
    return (
     // <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: '24px', // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1}}
              >
                {currentComponentName}
              </Typography>
              

              <div style={{ marginLeft: '40%', marginTop: '4px' }}>
                <Stack spacing={2} direction={'row'}>
                  <IconButton aria-label="search"  color="primary" onClick={()=>{handleSearch(searchValue)}}>
                    <SearchIcon/>
                  </IconButton>
      <Autocomplete
        style={{ width: 300 }}
        freeSolo
        onChange={(e, value) => {setSearchValue(value)}}
        autoComplete
        autoHighlight
        options={!!allUsers && Array.isArray(allUsers) ? allUsers : []}
        renderInput={(params) => (
          <TextField {...params}
            onKeyDown={handelEnter}
            //onSubmit={()=>{console.log("nAJSU")}}
            
            variant="outlined"
            label="Search User..."
          />
        )}
      />
      </Stack>
    </div>




            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <Stack spacing={0}  component="nav">
              {mainListItems}
              <Divider sx={{ my: 1 }} />
              {secondaryListItems}
            </Stack>
          </Drawer>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              minHeight: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            
            {/*Main Box*/}
            <Routes>
                <Route path="/dashboard" element={<Dashboard/>} />
                <Route path="/cards" element={<Cards/>} />
                <Route path="/mycollection" element={<MyCollection/>} />
                <Route path="/mydecks" element={<MyDecks/>} />
                <Route path="/market" element={<Market/>} />
                <Route path="/deals" element={<Deals/>}/>
                <Route path="/chatrooms" element={<ChatRooms/>} />
                <Route path="/priv/:withuser" element={<PrivRoom/>}/>
                <Route path="/viewprofile" element={<ViewProfile/>} />
                <Route path="/settings" element={<Settings/>} />
                <Route path="/cards/:id" element={<Card/>}/>
                <Route path="/decks/:id" element={<Deck/>}/>
                <Route path="/publicdecks/:name" element={<MyDecks/>}/>
                <Route path="/view/:name" element={<PublicProfile/>}/>
                <Route path="/collection/:username" element={<MyCollection/>}/>
                <Route path="*" element={<Dashboard/>}/>
            </Routes>

          </Box>
        </Box>
    //  </ThemeProvider>
    );
  }
  
