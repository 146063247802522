import { useContext, useEffect } from "react";
import { PageContext } from "../App";

export default function Settings()
{
    const {currentComponentName, setCurrentComponentName} = useContext(PageContext);
    useEffect(() => {
      setCurrentComponentName("Settings");
    }, [])

    return <></>

}