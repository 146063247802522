
import { useContext, useEffect , useState, useCallback} from "react";
import { useNavigate , useParams} from "react-router-dom";
import { PageContext ,TokenContext, UserContext} from "../App";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Box, Divider, Typography ,Paper} from "@mui/material";
import { DataGrid, GridToolbarQuickFilter, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from '@mui/icons-material/Search';
import Switch from '@mui/material/Switch';

function useForceUpdate(){
  const [v, setV] = useState(0);
  return () => setV(v => v+1);
}

export default function MyDecks()
{   const {name} = useParams();
    const { token, setToken } = useContext(TokenContext);
    const {user, setUser} = useContext(UserContext);
    const [decks, setDecks] = useState([]);
    const [loading, setLoading] = useState(false);
    const {currentComponentName, setCurrentComponentName} = useContext(PageContext);
    const forceUpdate = useForceUpdate();

    useEffect(() => {
      setCurrentComponentName(!!name ? `${name}'s Decks` : "My Decks");
      setLoading(true);

      const GetDecks = async () => {
       

        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/deck/allofuser/${!!name? name.toLowerCase() : user.username.toLowerCase()}`,
        {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }           
        }).then(response => response.json())
        .then(response => {
          const deckArray = [...response]
          setDecks(deckArray);
          setLoading(false);
          //console.log(deckArray);
        })
        .then(response => console.log("MyDecks: " , response))
        .catch(error => {console.log("MyDecksError: " ,error); setLoading(false);});

        } 

        GetDecks();      
    }, [name])

    const handleChangeIsPublic = (params) => {
      let boolInt = params.row?.IsPublic;
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/deck/setispublicfordeck/${params.row.Id}/${boolInt ? "0": "1"}`,
        {
            method: 'PUT',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }           
        }).then(()=> {params.row.IsPublic = !params.row.IsPublic; forceUpdate()})
        .catch(error => console.log("publicChange: " ,error));

    };
    const handleChangeLike = (params) => {
      let boolInt = params.row?.MyLike;
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/deck/${boolInt ? "dislikedeck": "likedeck"}/${params.row.Id}`,
        {
            method: boolInt ? 'DELETE' : 'PUT',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }           
        }).then(()=> {params.row.MyLike = !params.row.MyLike; forceUpdate()})
        .catch(error => console.log("likeChange: " ,error));

    };

    const navigate = useNavigate();

    const goToDeck = useCallback(
      (id,navigate) => () => {
        setTimeout(() => {
          navigate(`../decks/${id}`);

        });
      },
      [],
    );

    const columns = [
    
      { field: 'Id', headerName: 'Id', width: 100, valueGetter: (params)=> params.row?.Id},
      { field: 'DeckName', headerName: 'Deck Name', width: 300, valueGetter: (params)=> params.row?.Name } ,
      { field: 'DeckGame', headerName: 'DeckGame', width: 250 , valueGetter: (params)=> params.row?.Game},
      { field: 'Likes', headerName: 'Likes', width: 100  ,valueGetter: (params)=> params.row?.Likes},
      { field: 'actions', type: 'actions', width: 100, getActions: (params) => [
        <GridActionsCellItem
          icon={<SearchIcon />}
          label="Show"
          onClick={goToDeck(params.row?.Id, navigate)}
        />,
        <GridActionsCellItem
          icon={<ThumbUpIcon color={params.row?.MyLike ? 'success': 'disabled'} />}
          label="Like"
          onClick={() => {handleChangeLike(params)}}
        />
      ]},
      {field: 'IsPublic', headerName: 'Is public?', width: 150, renderCell: (params)=>{
          return (
            
              params.row?.Editable ? 
          <div><Switch checked={params.row?.IsPublic}
          onChange={(e) => {handleChangeIsPublic(params);}}
          ></Switch></div>
          : <div><Switch disabled checked={params.row?.IsPublic}></Switch></div>
            
          ); 
        }}
    ];
  
    return <>{loading? <Box
      justifyContent="center"
      sx={{ display: "flex", alignItems: "center", minHeight: "100%" }}
    >
      <CircularProgress />
    </Box>
    :
    <>
    <div style={{  width: '100%' }}>
      
      <DataGrid autoHeight  rows={decks} columns={columns} 
      getRowId={(decks) => decks.Id}
      
      initialState={{
        pagination: {paginationModel:{pageSize: 5 }},
        columns:{
          columnVisibilityModel:{
            Id: false
          }
        }
      }}
      pageSizeOptions={[5,10,25,100]}
      slots={{toolbar: GridToolbar}}
      slotProps={{
        toolbar:{showQuickFilter: true}
      }}
      />
    </div>
      
      </>
    }</>

}