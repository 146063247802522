import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {Link as RouterLink} from 'react-router-dom';
import { Link } from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function SuccesfulRegisterModal({open,setOpen}) {

  const handleClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          One of us!
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Your account has been created.<br/> You can <Link component={RouterLink} to="/login">Log In</Link> now!
        </Typography>
      </Box>
    </Modal>
  );
}
