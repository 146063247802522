import {
  Stack,
  Grid,
  Paper,
  Button,
  List,
  Typography,
  IconButton,
} from "@mui/material";
import { green, pink } from "@mui/material/colors";
import { useContext, useEffect, useState } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { TokenContext } from "../App";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormControl } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import CurrencyInput from "react-currency-input-field";
import "./Price.css";
import { grey } from "@material-ui/core/colors";

export default function CardOrderBook(props) {
  const handleDelete = (orderId) => {
    if (!!orderId) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/order/${orderId}`, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            let indexB = bids.findIndex((b) => b.Id === orderId);
            let indexA = asks.findIndex((a) => a.Id === orderId);

            if (indexB !== -1) {
              let copyBids = JSON.parse(JSON.stringify(bids));

              copyBids.splice(indexB, 1);

              setBids(copyBids);
            }
            if (indexA !== -1) {
              let copyAsks = JSON.parse(JSON.stringify(asks));

              copyAsks.splice(indexA, 1);

              setAsks(copyAsks);
            }
          }
        })
        .catch((error) => console.log(error));
    }
  };

  function renderBid(props) {
    const { index, style } = props;

    return (
      <ListItem sx={{color: (bids[index].IsLocked) ? grey[500] : null}}
        style={style}
        key={index}
        component="div"
        disablePadding
        secondaryAction={
          <IconButton
            edge="end"
            disabled={!!bids[index].Mine ? !bids[index].Mine : true}
            onClick={
              !!bids[index].Id
                ? () => {
                    handleDelete(bids[index].Id);
                  }
                : () => {}
            }
          >
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemButton>
          <ListItemText
            primary={!!bids[index] ? `${bids[index].Price} PLN` : null}
            secondary={
              !!bids[index]
                ? bids[index].User + " | " + bids[index].Datetime + ((bids[index].IsLocked)?` 🔒`:``)
                : null
            }
          />
        </ListItemButton>
      </ListItem>
    );
  }

  function renderAsk(props) {
    const { index, style } = props;

    return (
      <ListItem sx={{color: (asks[index].IsLocked) ? grey[500] : null}}
        style={style}
        key={index}
        component="div"
        disablePadding
        secondaryAction={
          <IconButton
            edge="end"
            disabled={!!asks[index].Mine ? !asks[index].Mine : true}
            onClick={
              !!asks[index].Id
                ? () => {
                    handleDelete(asks[index].Id);
                  }
                : () => {}
            }
          >
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemButton>
          <ListItemText
            primary={!!asks[index] ? `${asks[index].Price} PLN` : null}
            secondary={
              !!asks[index]
                ? asks[index].User + " | " + asks[index].Datetime + ((asks[index].IsLocked)?` 🔒`:``)
                : null
            }
          />
        </ListItemButton>
      </ListItem>
    );
  }

  const [bids, setBids] = useState([]);
  const [asks, setAsks] = useState([]);
  const [bidValue, setBidValue] = useState(0.01);
  const [askValue, setAskValue] = useState(0.01);
  const { token, setToken } = useContext(TokenContext);
  const { id } = useParams();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/order/bids/${id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("BIDS: ", response);
        setBids(response);
      })
      .catch((error) => console.log(error));

    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/order/asks/${id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("ASKS: ", response);
        setAsks(response);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleOnValueChangeBid = (render, _, values) => {
    if (!values.float) {
      setBidValue(0.01);
      return;
    }
    if (values.float < 0.01) {
      setBidValue(0.01);
      return;
    }
    if (values.float < 0) {
      setBidValue(Math.abs(values.float));
      return;
    }
    if (values.float > 999999999.99) {
      setBidValue(999999999.99);
      return;
    }
    setBidValue(values.float);
  };
  const handleOnValueChangeAsk = (render, _, values) => {
    console.log(values.float);
    if (!values.float) {
      setAskValue(0.01);
      return;
    }
    if (values.float < 0.01) {
      setAskValue(0.01);
      return;
    }
    if (values.float < 0) {
      setAskValue(Math.abs(values.float));
      return;
    }
    if (values.float > 999999999.99) {
      setAskValue(999999999.99);
      return;
    }
    setAskValue(values.float);
  };

  const handleClickBid = () => {
    const bodyObj = {
      Price: bidValue,
    };
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/order/bid/${id}`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyObj),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("BIDing: ", response);
        setBids(response);
      })
      .catch((error) => console.log(error));
  };

  const handleClickAsk = () => {
    const bodyObj = {
      Price: askValue,
    };
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/order/ask/${id}`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyObj),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("Askimg: ", response);
        setBids(response);
      })
      .catch((error) => console.log(error));
  };
  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid item xs={6}>
        <Stack
          alignItems={"center"}
          fullWidth
          spacing={0}
          marginLeft={2}
          marginRight={2}
          marginBottom={2}
          pl={0}
          pr={5}
        >
          <Typography variant="button" fontSize={16}>
            Price
          </Typography>
          <CurrencyInput
            className="PriceInput"
            value={bidValue}
            onValueChange={handleOnValueChangeBid}
            placeholder="Bid Price"
            suffix={` PLN `}
            step={0.01}
          />
        </Stack>
        <Paper sx={{ height: 400, marginRight: 5 }}>
          <Button
            fullWidth
            variant="contained"
            color={"success"}
            onClick={handleClickBid}
          >
            Bid
          </Button>
          <FixedSizeList
            height={362}
            //width={360}
            itemSize={72}
            itemCount={!!bids.length ? bids.length : 0}
            overscanCount={5}
          >
            {renderBid}
          </FixedSizeList>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Stack
          alignItems={"center"}
          fullWidth
          spacing={0}
          marginLeft={2}
          marginRight={2}
          marginBottom={2}
          pl={0}
          pr={5}
        >
          <Typography variant="button" fontSize={16}>
            Price
          </Typography>
          <CurrencyInput
            className="PriceInput"
            value={askValue}
            onValueChange={handleOnValueChangeAsk}
            placeholder="Ask Price"
            suffix={` PLN `}
            step={0.01}
          />
        </Stack>
        <Paper sx={{ height: 400, marginRight: 5 }}>
          <Button
            fullWidth
            variant="contained"
            color={"error"}
            onClick={handleClickAsk}
          >
            Ask
          </Button>
          <FixedSizeList
            height={362}
            //width={360}
            itemSize={72}
            itemCount={!!asks.length ? asks.length : 0}
            overscanCount={5}
          >
            {renderAsk}
          </FixedSizeList>
        </Paper>
      </Grid>
    </Grid>
  );
}
