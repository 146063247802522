import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import "./Login.css";
import { Alert } from "bootstrap";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { style } from "@mui/system";
import {
  TextField,
  Grid,
  Avatar,
  Typography,
  Link,
  Divider,
} from "@mui/material";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import { deepPurple, grey, blueGrey } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import Copyright from "../Copyright/Copyright.js";
import jwt from "jwt-decode";
import { UserContext } from "../App";
import { GoogleLogin } from "@react-oauth/google";
import SuccesfulRegisterModal from './SuccessfulRegister'

async function loginUser(credentials) {
  console.log(process.env.NODE_ENV);
  console.log(process.env.REACT_APP_BACKEND_URL);
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/authenticate/login`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
      },
      body: JSON.stringify(credentials),
    }
  );

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    alert("Błędne dane logowania!");
    throw new Error(message);
  }

  const responseJson = await response.json();
  return responseJson;
}

function Login({ setToken }) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();


  //Google successful register
  const [openModal, setOpenModal] = React.useState(false);

  const { user, setUser } = useContext(UserContext);

  const handleSubmit = async (e, navigate) => {
    e.preventDefault();
    const token = await loginUser({ username, password }).catch((error) => {
      console.log(error.message);
    });

    if (token !== "undefined" && token !== undefined) {
      setToken(token.token);
      console.log(token);
      const tokenData = jwt(token.token);
      setUser({
        loginname: tokenData.username,
        username: tokenData.publicname,
        email: tokenData.email,
      });

      navigate("/main");
    }
  };

  const googleLoginUser = async (res) => {
    console.log(res);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/authenticate/googlelogin`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
        },
        body: JSON.stringify(res),
      }
    );

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;

      if (response.status !== 500) {
        alert("Błędne dane logowania!");
      } else {
        //console.log(response);
        const responseJson500 = await response.json();
        alert("Error! " + responseJson500.message);
      }
      throw new Error(message);
    }
    const responseJson = await response.json();
    console.log(responseJson);
    return responseJson;
  };

  const handleSubmitGoogle = async (e, navigate) => {
    //e.preventDefault();
    const token = await googleLoginUser(e).catch((error) => {
      console.log(error.message);
    });

    if (token !== "undefined" && token !== undefined) {
      if (token.hasOwnProperty("message")) {
        if (token.message == "User created successfully!") {
          setOpenModal(true);
        }
      } else {
        setToken(token.token);
        console.log(token);
        const tokenData = jwt(token.token);
        setUser({
          loginname: tokenData.username,
          username: tokenData.publicname,
          email: tokenData.email,
        });

        navigate("/main");
      }
    }
  };

  const paperStyle = {
    padding: 25,
    height: "70vh",
    minHeight: "450px",
    width: 280,
    margin: "20px auto",
  };
  const buttonStyle = { marign: "8px 0" };

  return (
    <Grid align="center">
      <Paper elevation={3} style={paperStyle}>
        <Grid align="center">
          <Avatar sx={{ bgcolor: deepPurple["A700"], marginBottom: 5 }}>
            <LoginOutlinedIcon />
          </Avatar>
        </Grid>
        <form
          onSubmit={(e) => {
            handleSubmit(e, navigate);
          }}
        >
          <Stack spacing={2}>
            <TextField
              label="Username"
              placeholder="Enter username"
              fullWidth
              required
              autoComplete="username"
              onChange={(e) => setUsername(e.target.value)}
            ></TextField>
            <TextField
              label="Password"
              placeholder="Enter password"
              fullWidth
              required
              type="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            ></TextField>
            <Button
              style={buttonStyle}
              type="submit"
              variant="contained"
              fullWidth
            >
              Login
            </Button>
            <Typography>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Typography>
            <Typography>
              <Link variant="body2" component={RouterLink} to="/register">
                {"Don't have an account? Sign Up"}
              </Link>
            </Typography>
          </Stack>
        </form>

        <Divider sx={{margin: 1}}/>

        <div id="googleButton">
          <GoogleLogin
            onSuccess={(res) => handleSubmitGoogle(res, navigate)}
            onFailure={(res) => console.log("BAD: ", res)}
          />
        </div>
        <SuccesfulRegisterModal open={openModal} setOpen={setOpenModal}/>
      </Paper>
      <Copyright sx={{ mt: 5 }} />
    </Grid>
  );

  Login.propTypes = {
    setToken: PropTypes.func.isRequired,
  };
}

export default Login;
