import {
  Stack,
  Select,
  Box,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Modal, Typography } from "@mui/material";
import { pink, yellow, blue } from "@mui/material/colors";
import { useContext, useEffect, useState } from "react";
import { TokenContext } from "../App";
export default function AddCardToDeck({ open, setOpen, params }) {
  const [deck, setDeck] = useState("");
  const { token, setToken } = useContext(TokenContext);

  const [deckList, setDeckList] = useState([]);

  useEffect(() => {
    const GetDecks = async () => {
      var bodyObj = { Game: params.Game };
      let response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/deck/all`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(bodyObj),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          const deckArray = [...response];
          setDeckList(deckArray);
        })
        .then((response) => console.log(deckList))
        .catch((error) => console.log(error));
    };
    GetDecks();
  }, [open]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleChange = (event) => {
    setDeck(event.target.value);
  };

  const handleSubmit = (e, params) => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/deck/addcardtodeck/${
        params.Id
      }/${deck}`,
      {
        method: 'POST',
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        setOpen(false);
        console.log(response.status)
      })
      .catch((error) => console.log("addToDeckErr: ", error));
  };
  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Add this card to your deck?
        </Typography>
        <Typography color={pink[500]}>{params.Name}</Typography>
        <Typography color={pink[500]}>Version: {params.Version}</Typography>
        <Typography color={pink[500]}>Code: {params.Code}</Typography>
        <Typography color={pink[500]}>Rarity: {params.Rarity}</Typography>
        <Typography color={blue[200]}> {params.Expansion}</Typography>
        <Typography color={blue[200]}>{params.Game}</Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
          Choose your target deck
        </Typography>
        <FormControl required>
          <InputLabel id="deck-label" required>
            Deck
          </InputLabel>
          <Select
            labelId="deck-label"
            id="deckSelect"
            value={deck}
            label="Deck *"
            onChange={handleChange}
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {!!deckList
              ? deckList.map((deck) => {
                  return (
                    <MenuItem key={deck.Id} value={deck.Id}>
                      {deck.Name}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
        </FormControl>
        <Stack direction="row" spacing={2} mt={2}>
          <Button
            style={{ marign: "8px 0" }}
            type="submit"
            variant="contained"
            fullWidth
            onClick={(e) => {
              handleSubmit(e, params);
            }}
          >
            Submit
          </Button>
          <Button
            style={{ marign: "8px 0" }}
            type="cancel"
            variant="outlined"
            color="error"
            fullWidth
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
