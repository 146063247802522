import Message from "./ChatMsg";
import { Box, Container, Grid } from "@mui/material";
import { useRef , useEffect} from "react";
export default function MessageContainer({ messages }) {
  const messageRef = useRef();

  useEffect(() => {
    if(messageRef && messageRef.current) {
      //const { scrollHeight, clientHeight} = messageRef.current;
      messageRef.current.scrollIntoView({behavior: 'smooth'});

    }
  }, [messages]);

  return (
    <Box sx={{ minHeight: "82vh", maxHeight: "82vh", overflow: "auto", pt: 1 }}>
      {Array.isArray(messages) && messages.length > 0
        ? messages.map((m, index) => (
            <Message key={index} message={m}></Message>
          ))
        : null}
        <div ref={messageRef}/>
    </Box>
  );
}
