function Test() {   
    return (  
        <>  
            <div className="col-sm-12 btn btn-primary">  
                Test  
        </div>  
            <h1>Test</h1>  
        </>  
    )  
}  
  
export default Test