
import { Box, Divider, Typography ,Paper} from "@mui/material";
import { DataGrid, GridToolbarQuickFilter, GridToolbar } from "@mui/x-data-grid";
import { useContext , useEffect, useState, useCallback} from "react";
import { CardsContext, PageContext, TokenContext } from "../App";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import { GridActionsCellItem } from "@mui/x-data-grid";

export default function MyCollection() {

  const {username} = useParams();

  const { currentComponentName, setCurrentComponentName } =
    useContext(PageContext);

  const { token, setToken } = useContext(TokenContext);

  const [collection, setCollection] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0.0);

  const calculateCollectionValue = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/usercollection/value/${!!username ? username : ""}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(response => response.json())
    .then(response => {
      if(!isNaN(response)){
        setValue(response);
      }
      console.log(response);
    }).catch(error => console.log(error))

  };


  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setCurrentComponentName(!!username ? `${username}'s Collection`: "My Collection");
    setLoading(true);

      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/usercollection/${!!username ? username : ""}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => 
        Promise.all([response,response.json()]
      ))
      .then(([response,json]) => {
        if(!response.ok){
          console.log(response.status);
          throw Error(`Respsonse status ${response.status} (${response.statusText}): ${json.message}`);
        }
        if(!!json[0])
        {
          setEditable(json[0].Editable)
        }
        return [...json];
      })
      .catch(error => {console.log(error); return "ERROR"})
      .then((json)=>{
        console.log(json);
        if(json === "ERROR"){
        console.log(json);
        setLoading(false);
        //alert("Too many requests!");
      }
      else{
        setCollection(json);
        calculateCollectionValue();
        setLoading(false);
      }
      })
  }, []);

  const handleRemove = (params) => 
  {
    let cardId = params.row?.Cards?.Id
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/usercollection/removecard/${cardId}`, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((respone)=> {
      console.log("X!")
    }).catch((error) => {
      console.log("RemoveCardErr: ",error);
      
    }).finally(()=>{
      
      let index = collection.findIndex(c=> c.Cards.Id === cardId)
      console.log("Index:" ,index)
      if(index !== -1){
          let copyCollection = JSON.parse(JSON.stringify(collection));
          if(copyCollection[index].Quantity == 1){
            copyCollection.splice(index,1);
          }
          else
          {
            copyCollection[index].Quantity--;
          }
          setCollection(copyCollection);
          
      }
    });
  };
  const columns = [
    
    { field: 'Quantity', headerName: 'Quantity', width: 100, valueGetter: (params)=> params.row?.Quantity + 'x'},
    { field: 'CardName', headerName: 'Card Name', width: 300, valueGetter: (params)=> params.row?.Cards?.Name } ,
    { field: 'CardCode', headerName: 'Card Code', width: 150 , valueGetter: (params)=> params.row?.Cards?.Code},
    { field: 'CardGame', headerName: 'Game', width: 250  ,valueGetter: (params)=> params.row?.Cards?.Game},
    { field: 'CardExpansion', headerName: 'Expansion', width: 150 , valueGetter: (params)=> params.row?.Cards?.Expansion},
    { field: 'CardVersion', headerName: 'Version', width: 100,  valueGetter: (params)=> params.row?.Cards?.Version},
    { field: 'CardRarity', headerName: 'Rarity', width: 150 , valueGetter: (params)=> params.row?.Cards?.Rarity},
    { field: 'actions', type: 'actions', width: 100, getActions: (params) => [
      <>
      {editable? 
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Remove"
        onClick={(e)=>{handleRemove(params)}}
      />
      :
      <></>
      }
      </>
    ]
    }
  ];

  return (
    <>
    {loading? <Box
      justifyContent="center"
      sx={{ display: "flex", alignItems: "center", minHeight: "100%" }}
    >
      <CircularProgress />
    </Box>
    :
    <>
    <div style={{  width: '100%' }}>
      
    <Paper elevation={20} style={{margin: '15px'}} justifyContent='center'>   
      <Typography align='center' width={'auto'} variant="button" display={'block'}>Estimated Value:&emsp; <span style={{color: "lightgreen"}}>{value.toFixed(2)} PLN</span></Typography>
      </Paper>
      <DataGrid autoHeight  rows={collection} columns={columns} 
      getRowId={(collection) => collection.Cards.Id}
      
      initialState={{
        pagination: {paginationModel:{pageSize: 5 }},
        columns:{
          columnVisibilityModel:{
            Id: false
          }
        }
      }}
      pageSizeOptions={[5,10,25,100]}
      slots={{toolbar: GridToolbar}}
      slotProps={{
        toolbar:{showQuickFilter: true}
      }}
      />
    </div>
      
      </>
    }
    </>
  );
}
