import { Avatar, Typography } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import PersonIcon from "@mui/icons-material/Person";
import {Stack, Box} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { PageContext, TokenContext, UserContext } from "../App";
import { Switch } from "@material-ui/core";


function ViewProfile() {

  const {user, setUser} = useContext(UserContext)
  const {token, setToken} = useContext(TokenContext);
  const [isPublicCollection, setIsPublicCollection] = useState(false);

  const {currentComponentName, setCurrentComponentName} = useContext(PageContext);
  useEffect(() => {
    setCurrentComponentName(user.username+"'s profile");

    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/usercollection/ispublic`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then(response => response.json())
    .then(response => {
      if(!!response){
        console.log(response);
        setIsPublicCollection(response);
      }
    })
    .catch(error => console.log(error))
  }, [])

  const handleChangeIsPublic = (e) => 
  {
    console.log(e.target.checked);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/usercollection/setIsPublicForCollection/${e.target.checked ? 1 : 0}`, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then(
      response => response.json()
    )
    .then(response => {setIsPublicCollection(response)})
    .catch(error => console.log(error))
  };

  return (
    <Stack >
      <Stack >
    <Stack margin={5}
      spacing={{ xs: 1, sm: 4 }}
      //direction="row"
      alignItems="center"
    >

      <Avatar
        sx={{backgroundColor: green[500], width: 50, height: 50, mt: 5, ml: 5 }}
      >
        <PersonIcon fontSize="large" />
      </Avatar>
    
    <Typography variant="button" sx={{ fontSize: "2rem"}} > <nobr>Hi, {user.username} !</nobr> </Typography>

    </Stack>
    <Box ml={5}>
    Public Collection:&emsp;<Switch 
    checked={!!isPublicCollection ? isPublicCollection : false}
    onChange={(e) => {handleChangeIsPublic(e);}}
    ></Switch>
    </Box>
    </Stack>
    </Stack>
  );
}

export default ViewProfile;
