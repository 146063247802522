import { Stack, Button, Container, Box } from "@mui/material";
import MessageContainer from "./MessageContainer";
import SendMessageForm from "./SendMsg";
import { red , grey} from "@mui/material/colors";

export default function Chat({ messages, sendMessage, closeConnection }) {
  return (
    <Stack pt="5">
      <Button color="primary" size="large" f
        sx={{ minHeight: "10%" , backgroundColor:  red[500], color: grey[900], fontWeight: 999}}
        varian="contained"
        onClick={() => closeConnection()}
      >
        Leave
      </Button>

      <MessageContainer messages={messages} />

      <SendMessageForm sendMessage={sendMessage} />
    </Stack>
  );
}
