import { FormControl, Box, Grid, TextField, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";

const SendMessageForm = ({ sendMessage }) => {
  const [input, setInput] = useState("");

  const handleSend = (e) => {
    e.preventDefault();
    if (input.trim() !== "") {
      sendMessage(input);
      setInput("");
    }
  };
  return (
    <form onSubmit={handleSend}>
      <Box
        sx={{
          minHeight: "10%",
          minWidth: "100%",
          p: 2,
          backgroundColor: "background.default",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TextField
              size="small"
              fullWidth
              placeholder="Type a message"
              variant="outlined"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              type="submit"
              endIcon={<SendIcon />}
              //onClick={handleSend}
              disabled={!input}
            ></Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default SendMessageForm;
