
import { useContext, useEffect } from "react";
import { PageContext } from "../App";

export default function Dashboard()
{
    const {currentComponentName, setCurrentComponentName} = useContext(PageContext);
    useEffect(() => {
      setCurrentComponentName("Dashboard");
    }, [])

    return <></>

}