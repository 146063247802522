
import { useContext, useEffect, useState } from "react";
import { PageContext, TokenContext, UserContext } from "../App";
import { HubConnectionBuilder, HttpTransportType, hub, LogLevel } from "@microsoft/signalr";
import { Button } from "@mui/material";
import Chat from "./Chat";
import { pink } from "@mui/material/colors";
import {Stack} from "@mui/material";

export default function ChatRooms()
{
    const {currentComponentName, setCurrentComponentName} = useContext(PageContext);
    const {user, setUser} = useContext(UserContext);
    const [room, setRoom] = useState();
    const {token, setToken} = useContext(TokenContext);
    const [messages, setMessages] = useState([]);
    const [joinButtonDisabled, setJoinButtonDisabled] = useState(false);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );
      
    useEffect(() => {

      setCurrentComponentName("Chat Rooms");
      setRoom("#General");
    }, [])


    const [connection, setConnection] = useState();
    const joinRoom = async (user, room, messages, setMessages) => {
        try{
            const getAccessToken = () => {return token};
            const connection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_BACKEND_URL}/chat`,{
                 //skipNegotiation:true,
                 //transport: HttpTransportType.WebSockets,
                 accessTokenFactory: getAccessToken,
                })
                .configureLogging(LogLevel.Information)
            .build();

            connection.on("ReceiveMessage", (user, message)=> {
                setMessages(messages => [...messages, {user,message}]);
            });


            connection.onclose(e => {
                setConnection();
                setMessages([]);
            })

            await connection.start();
            await connection.invoke("JoinRoom", {user, room});
            //console.log("before");
            //await delay(2000);
            //console.log("after");
            setJoinButtonDisabled(false);
            setConnection(connection);
        }
        catch(e){
            console.log(e);
        }
    }

    
    const sendMessage = async (message) =>
    {
        try{
            connection.invoke("SendMessage", message);
        }
        catch(e){
            console.log(e);
        }
    }

    const closeConnection = async () => {
        try{
            await connection.stop();
        }catch(e){
            console.log(e);
        }
    }


    return (<Stack alignItems={'center'} mt={3}><div style={{backgroundColor:'#fffffff' , padding: 0, width: '50%'}}  >
        {!connection ? <Button id="joinGeneralChatBtn" disabled={joinButtonDisabled} onClick={(e)=> {setJoinButtonDisabled(true); console.log("JOIN: " + messages);joinRoom(user.username,room, messages, setMessages)}}>Join #General</Button> : <Chat messages={messages} sendMessage={sendMessage} closeConnection={closeConnection}/>}
        </div>
        </Stack>
    );

}