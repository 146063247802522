import {
    Stack,
    Select,
    Box,
    FormControl,
    InputLabel,
    Button,
  } from "@mui/material";
  import MenuItem from "@mui/material/MenuItem";
  import { Modal, Typography } from "@mui/material";
  import { pink , blue} from "@mui/material/colors";
  import { useContext, useEffect, useState } from "react";
import { TokenContext } from "../App";
  export default function AddCardToCollection({ open, setOpen, params }) {

  
      useEffect(()=>{
          
      },[]);
      
    const {token, setToken} = useContext(TokenContext);
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "400",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };
  


    const handleSubmit = async (e) => {
        console.log("AddToCollectionSubmit", params)
        if(!!params.Id && params.Id != -999)
        {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/usercollection/addcard/${params.Id}`,
            {
              method: 'POST',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              }  
            });
            setOpen(false);
        }
    };

    return (
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add this card to your collection?
          </Typography>
          <Typography color={pink[500]}>{params.Name}</Typography>
          <Typography color={pink[500]}>Version: {params.Version}</Typography>
          <Typography color={pink[500]}>Code: {params.Code}</Typography>
          <Typography color={pink[500]}>Rarity: {params.Rarity}</Typography>
          <Typography color={blue[200]}> {params.Expansion}</Typography>
          <Typography color={blue[200]}>{params.Game}</Typography>
          <Stack direction="row" spacing={2} mt={2}>
            <Button
              style={{ marign: "8px 0" }}
              type="submit"
              variant="contained"
              fullWidth
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button
              style={{ marign: "8px 0" }}
              type="cancel"
              variant="outlined"
              color="error"
              fullWidth
              onClick={()=>{setOpen(false)}}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    );
  }
  