import { Box, Divider, Typography } from "@mui/material";
import { DataGrid, GridToolbarQuickFilter, GridToolbar } from "@mui/x-data-grid";
import { useContext , useEffect, useState, useCallback} from "react";
import { CardsContext, PageContext, TokenContext } from "../App";
import CircularProgress from "@mui/material/CircularProgress";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import QueueIcon from '@mui/icons-material/Queue';
import AddCardToDeck from "./AddCardToDeckModal";
import {Modal} from "@mui/material";
import AddCardToCollection from "./AddCardToCollection";
export default function Cards() {

    const {currentComponentName, setCurrentComponentName} = useContext(PageContext);
    const {token, setToken} = useContext(TokenContext);
    const {cards, setCards} = useContext(CardsContext);

    const [openAddCardToDeck, setOpenAddCardToDeck] = useState(false);
    const handleAddCardToDeckClose = () => {
      setOpenAddCardToDeck(false);
    };

    const [openAddCardToCollection, setOpenAddCardToCollection] = useState(false);
    const handleAddCardToCollectionClose = () => {
      setOpenAddCardToCollection(false);
    };
    const [paramsAddCardToDeck, setParamsAddCardToDeck] = useState({
      Id: -999, Name: '', Game: '', Version: 0, Extension: '', Code: '' });

    const [paramsAddCardToCollection, setParamsAddCardToCollection] = useState({
      Id: -999, Name: '', Game: '', Version: 0, Extension: '', Code: '' });

    useEffect(() => {
      setCurrentComponentName("Cards");
      setLoading(true);

      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/cards/all`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => 
        Promise.all([response,response.json()]
      ))
      .then(([response,json]) => {
        if(!response.ok){
          console.log(response.status);
          throw Error(`Respsonse status ${response.status} (${response.statusText}): ${json.message}`);
        }
        return [...json];
      })
      .catch(error => {console.log(error); return "ERROR"})
      .then((json)=>{
        console.log(json);
        if(json === "ERROR"){
        console.log(json);
        setLoading(false);
        alert("Too many requests!");
      }
      else{
        setCards(json);
        setLoading(false);
      }
      })
    }, [])


    
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const goToCard = useCallback(
      (id,navigate) => () => {
        setTimeout(() => {
          navigate(`${id}`);

        });
      },
      [],
    );
    const columns = [
        { field: 'Id', headerName: 'Id', width: 150 },
        { field: 'Name', headerName: 'Card Name', width: 300} ,
        { field: 'Code', headerName: 'Card Code', width: 150 },
        { field: 'Game', headerName: 'Game', width: 250 },
        { field: 'Expansion', headerName: 'Expansion', width: 150 },
        { field: 'Version', headerName: 'Version', width: 100 },
        { field: 'Rarity', headerName: 'Rarity', width: 150 },
        { field: 'actions', type: 'actions', width: 100, getActions: (params) => [
          <GridActionsCellItem
            icon={<SearchIcon />}
            label="Show"
            onClick={goToCard(params.row.Id,navigate)}
          />,
          <GridActionsCellItem
            icon={<AddIcon />}
            label="Add to collection"
            onClick={() => {setParamsAddCardToCollection(params.row);setOpenAddCardToCollection(true);}}
          />,
          <GridActionsCellItem
            icon={<QueueIcon />}
            label="Add to deck"
            onClick={() => {console.log(params.row);setParamsAddCardToDeck(params.row);setOpenAddCardToDeck(true)}}
          />
        ]}
      ];

       // Otherwise filter will be applied on fields such as the hidden column id
      //const columns = React.useMemo(
        //() => columnsData.filter((column) => VISIBLE_FIELDS.includes(column.field)),
        //[columnsData],
      //);

  return (
    <>
    {loading? <Box
      justifyContent="center"
      sx={{ display: "flex", alignItems: "center", minHeight: "100%" }}
    >
      <CircularProgress />
    </Box>
    :
      <>
    <div style={{  width: '100%' }}>
      <DataGrid autoHeight  rows={cards} columns={columns} 
      getRowId={(card) => card.Id}
      
      initialState={{
        pagination: {paginationModel:{pageSize: 5 }},
        columns:{
          columnVisibilityModel:{
            Id: false
          }
        }
      }}
      pageSizeOptions={[5,10,25,100]}
      slots={{toolbar: GridToolbar}}
      slotProps={{
        toolbar:{showQuickFilter: true}
      }}
      />
    </div>
      <AddCardToDeck open={openAddCardToDeck} setOpen={setOpenAddCardToDeck} params={paramsAddCardToDeck}/>
      <AddCardToCollection open={openAddCardToCollection} setOpen={setOpenAddCardToCollection} params={paramsAddCardToCollection}/>
      </>
    }
    </>
  );
}


