import React, { useState } from "react";
import axios from "axios";
import Copyright from "../Copyright/Copyright.js";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper'
import { style } from "@mui/system";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { TextField, Grid, Avatar, Typography, Link } from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { deepPurple, grey } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import validator from "validator";
import {cssShake,myShake,spin} from "../cssAnimations/Shake.js"
import SuccesfulRegisterModal from "./SuccessfulRegister.js";

async function registerUser(credentials) {
  console.log(process.env.NODE_ENV);
  console.log(process.env.REACT_APP_BACKEND_URL);
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/authenticate/register`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
    },
    body: JSON.stringify(credentials),
  }).then(response => response.json());

  if(!response.ok)
  {
       if (response.hasOwnProperty("message")) console.log("Message:" + response.message);
  }

  //const responseJson = await response.json();
  return response;

}


const validatePasswordStrength = (value,setState) => {

  if (validator.isStrongPassword(value, {
    minLength: 8, minLowercase: 1,
    minUppercase: 1, minNumbers: 1, minSymbols: 1
  })) {
    setState(false);
  } else {
    setState(true);
  }
};

function Register(props) {
  const [username, setUsername] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorPasswordState, setErrorPasswordState] = useState(false);
  const [errorUsernameState, setErrorUsernameState] = useState(false);
  const [errorNameState, setErrorNameState] = useState(false);
  const [errorEmailState, setErrorEmailState] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);

  const allErrorStates = {
    errorPasswordState,
    setErrorPasswordState,
    errorUsernameState,
    setErrorUsernameState,
    errorNameState,
    setErrorNameState,
    errorEmailState,
    setErrorEmailState,
    openModal,
    setOpenModal
  };

  const handleSubmit = async (e, allErrorStates) => {
    e.preventDefault();
    if(allErrorStates.errorUsernameState || allErrorStates.errorEmailState || allErrorStates.errorPasswordState || allErrorStates.errorNameState) return;
    const token = await registerUser({ username, name, email, password }).catch((error) => {
      console.log(error.message);
    });
    console.log(token);
    if(token !== undefined && token !== "undefined")
    {
      if(token.hasOwnProperty('message'))
        {
          if(token.message == "User already exists!")
          {
            //setErrorUsernameState(true);
            allErrorStates.setErrorUsernameState(true);
          }
          if(token.message.includes("Name already exists!"))
          {
            allErrorStates.setErrorNameState(true);
          }
          if(token.message.includes("Code DuplicateEmail"))
          {
            allErrorStates.setErrorEmailState(true);
          }
          if(token.message.includes('User created successfully!'))
          {
            allErrorStates.setOpenModal(true);
          }
        }
    }
  }

  const paperStyle = { padding: 25, height: '70vh', minHeight: "500px", width: 280, margin: '20px auto' };
  const buttonStyle = { marign: '8px 0' };

  return (
    <Grid align='center'>
      <Paper elevation={3} style={paperStyle} >
        <Grid align='center'>
          <Avatar sx={{ bgcolor: deepPurple['A700'], marginBottom: 5 }} >
            <PersonAddIcon />
          </Avatar>
        </Grid>
        <form onSubmit={(e) => handleSubmit(e, allErrorStates)}>
          <Stack spacing={2}>
            <TextField helperText= {errorUsernameState ? "Ta nazwa jest zajęta!" : null } error = {errorUsernameState} label="Username" placeholder="Enter username" fullWidth required autoComplete="username" onChange={(e) => {setUsername(e.target.value); setErrorUsernameState(false);}}></TextField>
            <TextField helperText= {errorNameState ? "Ta nazwa jest zajęta!" : null } error = {errorNameState} label="Public Name" placeholder="Enter public name" fullWidth required autoComplete="name" onChange={(e) => {setName(e.target.value); setErrorNameState(false);}}></TextField>
            <TextField helperText={errorEmailState ? "Ten adres email jest zajęty!": null} error = {errorEmailState} label="Email" placeholder="Enter your Email" fullWidth required autoComplete="email" type="email" onChange={(e) => {setEmail(e.target.value); setErrorEmailState(false);}}></TextField>
            <TextField sx={!errorPasswordState? {} : {animation: `${myShake} 1s ease`}}helperText={!errorPasswordState ? "": "Min Length 8, Min Number 1, Min Special 1, Min Alpha 1"} error={errorPasswordState} label="Password" placeholder="Enter password" fullWidth required type="password" autoComplete="new-password" onChange={(e) => {setPassword(e.target.value); validatePasswordStrength(e.target.value,setErrorPasswordState);}}></TextField>
            <Button style={buttonStyle} type="submit" variant="contained" fullWidth>Register</Button>
            <Typography><Link variant="body2" component={RouterLink} to="/login" >Already have an account? Sign in</Link></Typography>
          </Stack>
        </form>
        <SuccesfulRegisterModal open={openModal} setOpen={setOpenModal}/>
      </Paper>
      <Copyright sx={{ mt: 5 }} />
    </Grid>
  );
}

export default Register
