import { useEffect, useContext, useState , useCallback} from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { PageContext, TokenContext, UserContext } from "../App";
import { Stack, Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { blue } from "@mui/material/colors";
import { GridActionsCellItem,DataGrid, GridToolbar } from "@mui/x-data-grid";
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';

function useForceUpdate(){
    const [v, setV] = useState(0);
    return () => setV(v => v+1);
  }
export default function Deck() {
  const forceUpdate = useForceUpdate();
  let { id } = useParams();
  const { token, setToken } = useContext(TokenContext);
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { currentComponentName, setCurrentComponentName } =
    useContext(PageContext);
  const [deck, setDeck] = useState();
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    setCurrentComponentName("Deck");
    setLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/deck/${id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      //.then(response => console.log("DeckView:" , response))
      .then((response) => {
        if (!!response) {
          setCurrentComponentName(
            `${response[0].Owner}'s deck: ${response[0].Name}`
          );
          setDeck(response[0]);
          setEditable(response[0].Editable)
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("DeckError: ", error);
        setLoading(false);
      });
  }, [id]);
  const navigate = useNavigate();

  const goToCard = useCallback(
    (id,navigate) => () => {
      setTimeout(() => {
        navigate(`../cards/${id}`);

      });
    },
    [],
  );

  const removeCard = (cardId, deckId) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/deck/removecardfromdeck/${cardId}/${deckId}`, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then((respone)=> {
        console.log("X!")
      }).catch((error) => {
        console.log("RemoveCardErr: ",error);
        
      }).finally(()=>{
        console.log("Cards: " ,deck.Cards)
        let index = deck.Cards.findIndex(c=> c.Card.Id === cardId)
        console.log("Index:" ,index)
        if(index !== -1){
            let copyDeckCards = JSON.parse(JSON.stringify(deck));
            copyDeckCards.Cards.splice(index,1);
            setDeck(copyDeckCards);
            
        }
      });
  }
  const columns = [
    { field: "Id", headerName: "Id", width: 150 , valueGetter: (params) => {return params.row.Card.Id}},
    { field: "Name", headerName: "Card Name", width: 300 , valueGetter: (params) => {return params.row.Card.Name}},
    { field: "Code", headerName: "Card Code", width: 150 , valueGetter: (params) => {return params.row.Card.Code}},
    { field: "Expansion", headerName: "Expansion", width: 150 ,valueGetter: (params) => {return params.row.Card.Expansion.Name}},
    { field: "Version", headerName: "Version", width: 100 , valueGetter: (params) => {return params.row.Card.Version}},
    { field: "Rarity", headerName: "Rarity", width: 150 , valueGetter: (params) => {return params.row.Card.Rarity.Name}},
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<SearchIcon />}
          
          label="Show"
          onClick={goToCard(params.row.Card.Id, navigate)}
        />,
        <>
        {editable ? 
          
          <GridActionsCellItem
            icon={<DeleteIcon />}
            disabled={editable}
            label="Remove"
            onClick={() => {removeCard(params.row.Card.Id, params.row.Deck.Id)
            }}
            />
          : 
          
          <></>
        }
        </>
      ],
    },
  ];

  return (
    <>
      {loading ? (
        <Box
          justifyContent="center"
          sx={{ display: "flex", alignItems: "center", minHeight: "100%" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Stack m={3} spacing={2} alignItems={"center"}>
          <Typography variant="button">
            Deck of game:&emsp;{" "}
            <span style={{ color: blue[200] }}>{deck?.Game}</span>
          </Typography>
          <div style={{ width: "100%" }}>
            <DataGrid
              autoHeight
              rows={!!deck ? !!deck.Cards? deck.Cards: [] : []}
              columns={columns}
              getRowId={(card) => card.Card.Id+Math.random()}
              initialState={{
                pagination: { paginationModel: { pageSize: 5 } },
                columns: {
                  columnVisibilityModel: {
                    Id: false,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, 100]}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { showQuickFilter: true },
              }}
            />
          </div>
        </Stack>
      )}
    </>
  );
}
