import { useParams } from "react-router-dom";
import { PageContext, TokenContext } from "../App";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Stack, Typography, Chip, Paper } from "@mui/material";
import { useEffect, useContext, useState } from "react";
import { pink } from "@mui/material/colors";
import CardOrderBook from "./CardOrderBook";

import { LineChart } from "@mui/x-charts/LineChart";

export default function Card() {
  
    const dataChartDumb = [
      {
        Price: 0,
        DateTime: new Date("1900-01-01T00:00:00")
      },
      
    ];
  let { id } = useParams();
  const { currentComponentName, setCurrentComponentName } =
    useContext(PageContext);
  const { token, setToken } = useContext(TokenContext);
  const [currentCard, setCurrentCard] = useState({});
  const [dataChart, setDataChart] = useState(dataChartDumb);

  useEffect(() => {
    setCurrentComponentName("Card");
    setLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/cards/${id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => Promise.all([response, response.json()]))
      .then(([response, json]) => {
        if (!response.ok) {
          throw Error(
            `Respsonse status ${response.status} (${response.statusText}): ${json.message}`
          );
        }
        console.log(json);
        setCurrentCard(json[0]);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
        console.log(currentCard);
      });


      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/dealhistory/${id}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then(response => response.json())
      .then(response =>
        {
          //console.log("Shutup" , response)
          if(Array.isArray(response))
          {
            //console.log("cmon",response)
            let d = response.map((g) => {return ({Price: g.Price, DateTime: new Date(g.DateTime) })});
            if(d.length === 0) d = [new {Price: 0, Date: new Date("1900-01-01T00:00:00") }]
            //console.log("co sie dzieje",d)
            setDataChart(
              d
            );
          }
        }).catch(error => console.log(error));

 
  }, []);

  const [loading, setLoading] = useState(false);

  const keyToLabel = {
    Price: "Price (PLN)",
  };
  const customize = {
    height: 350,
    legend: { hidden: true },
    margin: { top: 5, right : 0 },
  };

  return (
    <>
      {loading ? (
        <Box
          justifyContent="center"
          sx={{ display: "flex", alignItems: "center", minHeight: "100%" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width={"auto"}>
          <Stack spacing={2} m={2}>
            <Typography color={pink[500]} fontSize={30} fontWeight={"bold"}>
              {currentCard.Name}
            </Typography>
            <Stack spacing={2} direction="row">
              <img
                src={
                  "Id" in currentCard ? `/cards/card${currentCard.Id}.webp` : ""
                }
                alt="placeholdercard"
                width={260}
                height={364}
                border="0"
                loading="lazy"
              ></img>
              <Paper height={"auto"} sx={{ width: "auto" }}>
                <Stack spacing={1} noWrap>
                  <Typography noWrap={true}>
                    {" "}
                    Name:&emsp;
                    <span style={{ float: "right" }}>{currentCard.Name}</span>
                  </Typography>
                  <Typography nowrap>
                    Code:&emsp;
                    <span style={{ float: "right" }}>
                      {currentCard.Code}
                    </span>{" "}
                  </Typography>
                  <Typography noWrap>
                    Game:&emsp;{" "}
                    <span style={{ float: "right" }}>{currentCard.Game}</span>
                  </Typography>
                  <Typography noWrap>
                    Expansion:&emsp;
                    <span style={{ float: "right" }}>
                      {currentCard.Expansion}
                    </span>
                  </Typography>
                  <Typography noWrap>
                    Version:&emsp;
                    <span style={{ float: "right" }}>
                      {currentCard.Version}
                    </span>
                  </Typography>
                  <Typography noWrap>
                    Rarity:&emsp;
                    <span style={{ float: "right" }}>{currentCard.Rarity}</span>
                  </Typography>
                </Stack>
              </Paper>
              <Paper height={"auto"} sx={{ width: "50%", minWidth: 400 }}>
              <LineChart
                xAxis={[
                  {
                    id: "DateTime",
                    dataKey: "DateTime",
                    //valueFormatter: (v) => v.toString(),
                    scaleType: 'time',
                    valueFormatter: (date) => date.toLocaleDateString(),
                    tickNumber: 6,

                  },
                ]}
                series={ Object.keys(keyToLabel).map((key) => ({
                  dataKey: key,
                  label: keyToLabel[key],
                  color: pink[500],
                  showMark: true,
                  
                }))}
                dataset={(!!dataChart && dataChart.length > 0) ?  dataChart: dataChartDumb}
                {...customize}
              />

              </Paper>
            </Stack>
            <CardOrderBook currentCard={currentCard} marginLeft={5} />
          </Stack>
        </Box>
      )}
    </>
  );
}
