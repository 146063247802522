import { css, keyframes } from "@emotion/react";

export const myShake = keyframes`
from, to {
    transform: translate3D(0,0,0);
  }
15% { transform: translate3D(-4px,0, 0); }
30% { transform: translate3D(6px,0, 0) ;}
45% { transform: translate3D(-4px,0, 0) ;}
60% { transform: translate3D(6px,0, 0) ;}
`;

export const cssShake = css`
    animation: ${myShake} 15s infinite;
    animationiterationcount: 1;
  `;

export const spin = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

